<script>
/**
 * Navbar component
 */
export default {
  data() {
    return {
      isCondensed: false,
    };
  },
  props: {
    isWhiteNavbar: {
      type: Boolean,
    },
    navLight: {
      type: Boolean,
    },
    isIcons: {
      type: Boolean,
    },
  },

  mounted: () => {
    window.onscroll = function() {
      onwindowScroll();
    };
    
    function onwindowScroll() {
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        document.getElementById('topnav').classList.add('nav-sticky');
      } else {
        document.getElementById('topnav').classList.remove('nav-sticky');
      }

      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        document.getElementById('back-to-top').style.display = 'inline';
      } else {
        document.getElementById('back-to-top').style.display = 'none';
      }
    }

    const links = document.getElementsByClassName('side-nav-link-ref');
    let matchingMenuItem = null;
    for (let i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add('active');
      const parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add('active');
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add('active');
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add('active');
            const parent4 = parent3.parentElement;
            if (parent4) {
              const parent5 = parent4.parentElement;
              parent5.classList.add('active');
            }
          }
        }
      }
    }
  },
  methods: {
    /**
     * Toggle menu
     */
    toggleMenu() {
      this.isCondensed = !this.isCondensed;
      if (this.isCondensed) {
        document.getElementById('navigation').style.display = 'block';
      } else document.getElementById('navigation').style.display = 'none';
    },

    onMenuClick(event) {
      event.preventDefault();
      const nextEl = event.target.nextSibling.nextSibling;

      if (nextEl && !nextEl.classList.contains('open')) {
        const parentEl = event.target.parentNode;
        if (parentEl) {
          parentEl.classList.remove('open');
        }
        nextEl.classList.add('open');
      } else if (nextEl) {
        nextEl.classList.remove('open');
      }
      return false;
    },
  },
};
</script>

<template>
  <div>
    <!-- Navbar STart -->
    <header
      id="topnav"
      class="defaultscroll sticky"
      :class="{ 'bg-white': isWhiteNavbar === true }"
    >
      <div class="container">
        <!-- Logo container-->
        <div>
          <router-link class="logo" to="/" v-if="navLight !== true">
            <img src="/images/hihi-logo.png" height="24" alt="" />
          </router-link>
          <router-link class="logo" to="/" v-else>
            <img src="/images/hihi-logo.png" class="l-dark" height="24" alt="" />
            <img
              src="/images/hihi-logo-light.png"
              class="l-light"
              height="24"
              alt=""
            />
          </router-link>
        </div>
        <!-- End Logo container-->
        <div class="menu-extras">
          <div class="menu-item">
            <!-- Mobile menu toggle-->
            <a
              class="navbar-toggle"
              @click="toggleMenu()"
              :class="{ open: isCondensed === true }"
            >
              <div class="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </a>
            <!-- End mobile menu toggle-->
          </div>
        </div>

        <div id="navigation">
          <!-- Navigation Menu-->
          <ul
            class="navigation-menu"
            :class="{ 'nav-light': navLight === true }"
          >
            <li>
              <router-link to="/" class="side-nav-link-ref">首頁</router-link>
            </li>
            <li class="has-submenu">
              <a href="javascript:void(0)" @click="onMenuClick">服務項目</a>
              <span class="menu-arrow"></span>
              <ul class="submenu">
                <li>
                  <router-link :to="{name: 'Development'}" class="side-nav-link-ref">網站系統建置</router-link>
                </li>
                <li>
                  <router-link :to="{name: 'Hosting'}" class="side-nav-link-ref">網站託管/維運</router-link>
                </li>
                <!-- <li>
                  <router-link :to="{name: 'Consulting'}" class="side-nav-link-ref">數位資訊顧問</router-link>
                </li> -->
              </ul>
            </li>
            <li>
              <router-link to="/about" class="side-nav-link-ref">關於嗨嗨</router-link>
            </li>
            <li>
              <router-link to="/contact" class="side-nav-link-ref">聯絡我們</router-link>
            </li>
          </ul>
        </div>
      </div>
    </header>
  </div>
</template>
