<script>
import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  LinkedinIcon,
  MailIcon,
} from 'vue-feather-icons';
export default {
  data() {
    return {};
  },
  components: {
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    MailIcon,
  },
};
</script>

<template>
  <div>
    <!-- Footer Start -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
            <a href="#" class="logo-footer">
              <img src="/images/hihi-logo-light.png" height="24" alt="" />
            </a>
            <p class="mt-4">
              嗨嗨數位希望透過像打招呼一樣的親切感，與您一同打造兼具實用性及專業度的新創網站系統。透過嗨嗨數位，讓您的事業越來越嗨。
            </p>
            <p class="text-muted" style="font-size: 10px;">
              聯絡信箱: service@hihi.digital<br>
              服務電話: 0963-383-891<br>
              地址: 10482 台北市中山區建國北路3段92號3樓
            </p>
            <!-- <ul class="list-unstyled social-icon social mb-0 mt-4">
              <li class="list-inline-item">
                <a href="javascript:void(0)" class="rounded">
                  <facebook-icon class="fea icon-sm fea-social"></facebook-icon>
                </a>
              </li>
              <li class="list-inline-item ml-1">
                <a href="javascript:void(0)" class="rounded">
                  <instagram-icon
                    class="fea icon-sm fea-social"
                  ></instagram-icon>
                </a>
              </li>
              <li class="list-inline-item ml-1">
                <a href="javascript:void(0)" class="rounded">
                  <twitter-icon class="fea icon-sm fea-social"></twitter-icon>
                </a>
              </li>
              <li class="list-inline-item ml-1">
                <a href="javascript:void(0)" class="rounded">
                  <linkedin-icon class="fea icon-sm fea-social"></linkedin-icon>
                </a>
              </li>
            </ul> -->
            <!--end icon-->
          </div>
          <!--end col-->
          <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h5 class="text-light footer-head">SITEMAP</h5>
            <ul class="list-unstyled footer-list mt-4">
              <li>
                <router-link to="/" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i>首頁</router-link>
              </li>
              <li>
                <router-link :to="{name: 'About'}" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i>關於嗨嗨</router-link>
              </li>
              <li>
                <router-link :to="{name: 'Contact'}" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i>聯絡我們</router-link>
              </li>
            </ul>
          </div>
          <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h5 class="text-light footer-head">SERVICES</h5>
            <ul class="list-unstyled footer-list mt-4">
              <li>
                <router-link :to="{name: 'Development'}" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i>網站系統建置</router-link>
              </li>
              <li>
                <router-link :to="{name: 'Hosting'}" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i>網站託管/維運</router-link>
              </li>
              <!-- <li>
                <router-link :to="{name: 'Consulting'}" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i>資訊顧問服務</router-link>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
    </footer>
    <footer class="footer footer-bar">
      <div class="container text-center">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <div class="text-sm-left">
              <p class="mb-0">
                Copyright {{ new Date().getFullYear() }} © HiHi Digital All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
